import styled from 'styled-components'

const FooterStyles = styled.footer`
  background: var(--navy);
  padding: 24px 0;
`

const Footer = () => {
  return(
    <FooterStyles>
    </FooterStyles>
  )
}

export default Footer