import React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import 'normalize.css'
import AppProviders from 'context/app-providers'
import App from './App'

Sentry.init({
  dsn: 'https://63118595db634539948fc0b214a262c3@o1042898.ingest.sentry.io/6012108',
  integrations: [
    new Integrations.BrowserTracing(),
    new Sentry.Integrations.Breadcrumbs({
      console: false
    })
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

ReactDOM.render(
  <React.StrictMode>
    <AppProviders>
      <App />
    </AppProviders>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
